<template>
  <pc-login-container
    centerTitle="学生端"
    topSecondTitle="学生登录"
    @clickFirstTitle="clickTitle"
  >
    <v-col class="pa-0 ma-0">
      <input-phone
        :width="inputWidth"
        :height="inputHeight"
        :padding="inputPadding"
        :text="phone"
        type="tel"
        :maxLength="11"
        placeholder="手机号"
        @onInput="changePhone"
        icon="icon-phone"
        :error-messages="phoneErrorMsg"
      />

      <input-password
        :width="inputWidth"
        :height="inputHeight"
        :padding="inputPadding"
        :marginTop="inputMarginTop"
        :type="passwordType"
        placeholder="密码"
        @onInput="changePassword"
        :error-messages="passwordErrorMsg"
        :hasPasswordBtn="hasPasswordBtn"
        :isHidePassword="isHidePassword"
        @onPasswordClick="showOrHidePassword"
        :isVerifyPassword="false"
      />

      <submit-btn
        :width="btnWidth"
        :height="btnHeight"
        :marginTop="btnMarginTop"
        :loading="isShowBtnLoading"
        :disabled="!canClickBtn"
        @click="studentLoginPassword"
        >登录</submit-btn
      >

      <v-row
        class="pc-login-student-other pa-0 mx-0"
        justify="center"
        :style="{ marginTop: otherMarginTop }"
        >其他账号登录</v-row
      >

      <v-col class="pa-0 ma-0" @click="clickSms">
        <v-row
          class="pa-0 mx-0"
          align="center"
          justify="center"
          :style="{ marginTop: iconMarginTop }"
        >
          <v-img
            src="@/assets/ic_pc_login_sms.png"
            :style="{
              width: iconWidth,
              height: iconHeight,
              maxWidth: iconWidth,
              maxHeight: iconHeight,
            }"
          ></v-img>
        </v-row>
        <v-row
          class="pa-0 mx-0"
          align="center"
          justify="center"
          :style="{ marginTop: textMarginTop }"
        >
          短信快捷登录
        </v-row>
      </v-col>
    </v-col>
  </pc-login-container>
</template>

<script>
import PcMixins from "@/views/pc/_mixins/pc-mixins.js";
import { verifyPhone, studentLoginPassword } from "@/api/login";
import PcLoginContainer from "@/views/pc/components/pc-login-container";
import InputPhone from "@/components/input-phone";
import InputPassword from "@/components/input-password";
import SubmitBtn from "@/components/submit-btn";
export default {
  name: "pc-login-student",
  mixins: [PcMixins],
  props: {},
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
      inputWidth: "480px",
      inputHeight: "80px",
      inputPadding: "0px 10px",
      inputMarginTop: "22px",
      btnWidth: "400px",
      btnHeight: "64px",
      btnMarginTop: "30px",
      otherMarginTop: "100px",
      iconWidth: "40px",
      iconHeight: "40px",
      iconMarginTop: "30px",
      textMarginTop: "12px",

      phone: "", //手机号
      phoneErrorMsg: "", //手机号错误提示
      isPhone: false, //是不是正确的手机号格式
      isPhoneExit: true, //手机号是否已注册

      password: "", //密码
      passwordType: "password", //密码文本格式
      passwordErrorMsg: "", //密码错误提示
      isPassword: false, //是不是正确的密码格式
      hasPasswordBtn: false, //是否有密码显示隐藏按钮
      isHidePassword: true, //是否隐藏密码

      isShowBtnLoading: false, //按钮是否显示Loading
      canClickBtn: false, //能否点击按钮
    };
  },
  components: {
    PcLoginContainer,
    InputPhone,
    InputPassword,
    SubmitBtn,
  },
  computed: {},
  methods: {
    //点击左上角
    clickTitle() {
      this.$router.go(-1);
      // this.$router.replace({
      //   name: "pcLogin",
      // });
    },
    //判断按钮是否符合可以点击的条件
    isBtnCanClick() {
      return this.phone && this.isPhone && this.isPhoneExit && this.password;
    },
    //监听手机号改变
    changePhone(text) {
      this.phone = text;
      const pattern = /^[0-9]*$/;
      if (pattern.test(this.phone)) {
        //正则验证是否是手机号
        this.phoneErrorMsg = "";
        if (this.phone && this.phone.length == 11) {
          //达到11位时才去手机号查重
          this.isPhone = true;
          this.verifyPhone();
        } else {
          this.isPhone = false;
          this.canClickBtn = false;
        }
      } else {
        this.phoneErrorMsg = "手机号错误";
        this.isPhone = false;
        this.canClickBtn = false;
      }
    },
    //监听密码改变
    changePassword(text, isOk) {
      this.password = text;
      this.canClickBtn = this.isBtnCanClick();
      this.hasPasswordBtn = this.password.length > 0;
    },
    //显示或隐藏密码
    showOrHidePassword() {
      this.isHidePassword = !this.isHidePassword;
      if (this.isHidePassword) {
        this.passwordType = "password";
      } else {
        this.passwordType = "text";
      }
    },
    //手机号查重
    verifyPhone() {
      verifyPhone(this.phone)
        .then((res) => {
          console.log(">>>>>", "手机号查重成功");
          if (res.data.exist == 1) {
            //已注册
            this.isPhoneExit = true;
            this.canClickBtn = this.isBtnCanClick();
          } else {
            //未注册
            this.phoneErrorMsg = "该手机号尚未注册，请先注册";
            this.isPhoneExit = false;
            this.canClickBtn = false;
          }
        })
        .catch(({ code, msg }) => {
          console.log(">>>>>", "手机号查重失败");
          if (!msg) {
            return;
          }

          this.showSnackbar({ msg });
        });
    },
    //学生账号密码登录
    studentLoginPassword() {
      this.$track.event({
        category: "student_signin",
        name: "phone_with_pwd",
        value: this.phone,
        needJwt: false,
      });
      this.isShowBtnLoading = true;
      if (this.canClickBtn) {
        studentLoginPassword(this.phone, this.password)
          .then((res) => {
            this.isShowBtnLoading = false;
            if (!res) {
              console.log(">>>>>", "学生账号密码登录错误");
            } else {
              console.log(">>>>>", "学生账号密码登录成功");
              window.localStorage.setItem("jwt", res.data.jwt);
              this.$store.commit("auth/set", res.data.jwt);
              this.$track.event({
                category: "student_signin_success",
                name: "phone_with_pwd",
                value: this.$store.getters["auth/userId"],
              });
              this.$router.replace({
                name: "pcReaderIndex",
              });
            }
          })
          .catch(({ code, msg }) => {
            this.isShowBtnLoading = false;
            console.log(">>>>>", "学生账号密码登录失败");
            this.$track.event({
              category: "student_signin_fail",
              name: "phone_with_pwd",
              value: code,
              needJwt: false,
            });
            if (!msg) {
              return;
            }

            this.showSnackbar({ msg });
          });
      } else {
        this.isShowBtnLoading = false;
      }
    },
    //点击短信登录
    clickSms() {
      this.$router.push({ name: "pcLoginStudentSms" });
    },
    //返回像素值
    getSize(size) {
      return Math.round(size) + "px";
    },
  },
  watch: {},
  created() {
    this.screenWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    this.screenHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    let x = this.screenWidth / 1920;
    let y = this.screenHeight / 1080;
    this.inputWidth = this.getSize(480 * x);
    this.inputHeight = this.getSize(80 * y);
    this.inputPadding = "0px" + this.getSize(10 * x);
    this.btnWidth = this.getSize(400 * x);
    this.btnHeight = this.getSize(64 * y);
    this.btnMarginTop = this.getSize(30 * y);
    this.otherMarginTop = this.getSize(100 * y);
    this.iconWidth = this.getSize(40 * y);
    this.iconHeight = this.iconWidth;
    this.iconMarginTop = this.getSize(30 * y);
    this.textMarginTop = this.getSize(12 * y);
  },
};
</script>

<style lang="scss" scoped>
.pc-login-student-other {
  height: 25px;
  line-height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.pc-login-student-bottom-text {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
</style>
